import { getFunctions, httpsCallable } from 'firebase/functions';

export const sendMail = async (message) => {
  const functions = getFunctions();
  const addMailDocument = httpsCallable(functions, 'addMailDocument');

  console.log("message",message);
  
  try {
    // Ensure the message object has the expected structure
    const result = await addMailDocument({
      to: message.to,
      subject: message.subject,
      html: message.html,
    });
    console.log('Mail sent successfully:', result);
    return result;
  } catch (error) {
    console.error('Error sending mail:', error);
  }
};
