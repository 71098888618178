// App.js
import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import CbAsk from './components/CbAsk';
import Header from './components/Header';
import { handleIframeDrag } from './components/IframeDrag';
import { auth } from './firebase'; // Import the auth object
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  GithubAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth'; // Import necessary functions
import { LanguageProvider } from './components/LanguageContext'; // Import LanguageProvider
import HeaderAdmin from './components/HeaderAdmin';

function App() {
  const [aid, setAid] = useState('');
  const [user, setUser] = useState(null);
  const [messageResponsePairs, setMessageResponsePairs] = useState([]); // State variable for message-response pairs

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setAid(params.get('aid') || '');
  }, []);

  useEffect(() => {
    const iframeElement = window.frameElement;
    if (iframeElement) {
      iframeElement.addEventListener('mousedown', handleIframeDrag);
      return () => {
        iframeElement.removeEventListener('mousedown', handleIframeDrag);
      };
    }
    return () => {};
  }, []);

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    // Cleanup subscription
    return () => unsubscribe();
  }, []);

  // Function to handle email/password login
  const handleEmailLogin = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
      .then((result) => {
        console.log('User signed in with email:', result.user);
        return result.user; // Return the user object
      })
      .catch((error) => {
        console.error('Error during email login:', error);
        throw error; // Re-throw the error to be caught in LoginModal
      });
  };

  // Function to handle email/password sign-up
  const handleEmailSignUp = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password)
      .then((result) => {
        console.log('User signed up with email:', result.user);
        return result.user; // Return the user object
      })
      .catch((error) => {
        console.error('Error during sign-up:', error);
        throw error; // Re-throw the error to be caught in LoginModal
      });
  };

  // Function to handle provider login
  const handleLoginWithProvider = (providerName) => {
    let provider;
    switch (providerName) {
      case 'google':
        provider = new GoogleAuthProvider();
        break;
      case 'facebook':
        provider = new FacebookAuthProvider();
        break;
      case 'github':
        provider = new GithubAuthProvider();
        break;
      default:
        throw new Error('Unsupported provider');
    }

    return signInWithPopup(auth, provider)
      .then((result) => {
        console.log(`User signed in with ${providerName}:`, result.user);
        return result.user;
      })
      .catch((error) => {
        console.error(`Error during ${providerName} login:`, error);
        throw error;
      });
  };

  const handleLogout = () => {
    signOut(auth).catch((error) => {
      console.error('Error during logout:', error);
    });
  };

  return (
    <LanguageProvider>
      <div className="app-container">
        <Header
          user={user}
          handleLoginWithProvider={handleLoginWithProvider}
          handleEmailLogin={handleEmailLogin}
          handleEmailSignUp={handleEmailSignUp}
          handleLogout={handleLogout}
        />
        <HeaderAdmin
          user={user}
        />

        <div className="iframe-content">
          <CbAsk
            user={user}
            setMessageResponsePairs={setMessageResponsePairs} // Pass setter to CbAsk
            messageResponsePairs={messageResponsePairs}
          />
        </div>
      </div>
    </LanguageProvider>
  );
}

export default App;
