import React, { useState, createContext, useContext } from 'react';
import strings_en from './strings/strings'; // Import English strings
import strings_fi from './strings/strings_fi'; // Import Finnish strings

// Create a context for language
const LanguageContext = createContext();

// Create a custom hook to use the LanguageContext
export const useLanguage = () => useContext(LanguageContext);

// LanguageProvider component to wrap the app
export const LanguageProvider = ({ children }) => {
  // Set the default language to English (can be changed to browser's default language if desired)
  const [language, setLanguage] = useState('fi');

  // Function to change language dynamically
  const changeLanguage = (lang) => {
    setLanguage(lang);
  };

  // Choose the appropriate strings based on the selected language
  const strings = language === 'en' ? strings_en : strings_fi; // Add more languages if needed

  return (
    <LanguageContext.Provider value={{ strings, changeLanguage, language }}>
      {children}
    </LanguageContext.Provider>
  );
};
