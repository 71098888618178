import { sendMail } from "../utils";

export default function emailChatHistoryHTML(messageResponsePairs, email) {
  // Start by checking if there are any pairs to display
  if (messageResponsePairs.length === 0) {
    return '<p>No messages and responses available.</p>';
  }

  // Create the base HTML structure
  let chatHistoryHTML = '<div class="chat-history">';
  
  // Loop through all message-response pairs
  messageResponsePairs.forEach((pair, index) => {
    const { message, htmlOutput } = pair;
  
    // Append the message and response in nicely formatted HTML
    chatHistoryHTML += `
      <div class="chat-pair">
        <h4>Message ${index + 1}:</h4>
        <p><strong>User:</strong> ${message}</p>
        <h4>Response:</h4>
        ${htmlOutput}
      </div>
      <hr />
    `;
  });
  
  // Close the main div
  chatHistoryHTML += '</div>';

  // Prepare the message object
  const message = {
    to: email,
    subject: "Pikkubotin viestiketju",
    html: chatHistoryHTML,
  };

  // Send the mail
  sendMail(message);

  return "<b>Viestiketju on nyt matkalla sähköpostiisi, tarkasta varalta myös roskapostikansio.</b>";
}
  
  