// firebase.js
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  // apiKey: "AIzaSyDRxwIOZSx8VQqZINFWUlmqRqxfW7zOAXY",
  // authDomain: "chatbot-iframe-kaitsu-app.firebaseapp.com",
  // databaseURL: "https://chatbot-iframe-kaitsu-app-default-rtdb.europe-west1.firebasedatabase.app",
  // projectId: "chatbot-iframe-kaitsu-app",
  // storageBucket: "chatbot-iframe-kaitsu-app.appspot.com",
  // messagingSenderId: "350938111079",
  // appId: "1:350938111079:web:1096eb0abbd7d0f6cd08c2"

  // Your web app's Firebase configuration
  // from 
  // https://console.firebase.google.com/u/0/project/users-kaitsu-app/settings/general/web:YThhYTUwMzctNDQ0ZC00NTJkLTgxMGUtZjc0NWU0MmVmMzZh
  apiKey: "AIzaSyDRxwIOZSx8VQqZINFWUlmqRqxfW7zOAXY",
  authDomain: "pikkubotti.ahaavision.com",
  databaseURL: "https://chatbot-iframe-kaitsu-app-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "chatbot-iframe-kaitsu-app",
  storageBucket: "chatbot-iframe-kaitsu-app.appspot.com",
  messagingSenderId: "350938111079",
  appId: "1:350938111079:web:1096eb0abbd7d0f6cd08c2"

};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

// AppCheck debug token
// AB3574B0-EDC0-44F8-93CA-586FF9B30E4A

// Initialize App Check with reCAPTCHA v3 provider
// window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider('6Le_mmAqAAAAAC1WFTqOZBwOywK__uCdpAxcfPH6'),
//   isTokenAutoRefreshEnabled: true // Automatically refreshes App Check token
// });

export { app, auth, db};