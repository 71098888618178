// useCbAskAdapter.js
import { useAsBatchAdapter } from '@nlux/react';
import formatResponseAsHTML from './formatResponseAsHTML';
import emailChatHistoryHTML from './actions/emailChatHistoryHTML'
import React, { useEffect, useRef } from 'react';
import { AiChat } from '@nlux/react';
import '@nlux/themes/nova.css';
import './css/colors-light.css'; 
import { useLanguage } from './LanguageContext';
import image_botti from '../assets/botti.jpg';
import image_user from '../assets/user.jpg';
import { auth } from '../firebase';
import conversationStarters from './conversationStarters';

const cb_apiUrl = process.env.REACT_APP_CHATBEES_API_URL;
const cb_apiKey = process.env.REACT_APP_CHATBEES_API_KEY;

export function useCbAskAdapter({ setMessageResponsePairs, messageResponsePairs}) {

  const cbAskAdapter = useAsBatchAdapter(async (message) => {
    const user = auth.currentUser;

    // useEffect(() => {
    //   triggerParentLoginButtonClick();
    // }, []);

    const triggerParentLoginButtonClick = () => {
      // Send a message to the parent iframe to trigger the button click
      window.parent.postMessage('triggerButtonClick', 'https://pikkubotti.ahaavision.com');
    };

    // Ensure the user's message is added to the chat history
    setMessageResponsePairs((prevPairs) => [
      ...prevPairs,
      { message, htmlOutput: null }, // Temporarily set htmlOutput to null
    ]);

    // Apply CSS styles immediately for messages starting with "!"
    setTimeout(() => {
      applySpecialStyles();
    }, 0);

    if (message=="!login" ) {
      // triggerParentLoginButtonClick();
      return `<p>Tämä ominaisuus ei ole vielä aivan valmis, klikkaa PikkuBotti-tekstin vasemmalla puolella olevaa ikonia.</p>`;
    }

    if (message.startsWith('!') && user ) {
      const command = message.substring(1).trim();

      switch (command) {
        case 'email-me':
          // Generate chat history HTML
          const chatHistoryHTML = emailChatHistoryHTML([
            ...messageResponsePairs,
            { message, htmlOutput: "<p>Executing email logic...</p>" },
          ], user.email);

          // Apply CSS styles immediately after the response is added
          setTimeout(() => {
            applySpecialStyles();
          }, 200);

          return "<b>Viestiketju on nyt matkalla sähköpostiisi, tarkasta varalta myös roskapostikansio.</b>";

        default:
          return `<p>Unknown command: ${command}</p>`;
      }
    } else if (message.startsWith('!') && !user){
      return `<p>Kirjaudu sisään käyttääksesi komentoja.</p>`;
    }

    // Normal API fetch case
    try {
      const requestBody = {
        namespace_name: 'public',
        collection_name: 'ahaa-vision-com',
        question: message,
        top_k: 5,
        doc_name: null,
        history_messages: [],
      };

      const response = await fetch(cb_apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Api-Key': cb_apiKey,
        },
        body: JSON.stringify(requestBody),
      });

      const json = await response.json();
      const htmlOutput = formatResponseAsHTML(json);

      // Update the message-response pairs with the assistant's response
      setMessageResponsePairs((prevPairs) => {
        // Update the last entry (the user's message) with the assistant's response
        const updatedPairs = [...prevPairs];
        updatedPairs[updatedPairs.length - 1].htmlOutput = htmlOutput;
        return updatedPairs;
      });

      // Apply CSS styles immediately after the response is added
      setTimeout(() => {
        applySpecialStyles();
      }, 200);

      return htmlOutput;
    } catch (error) {
      console.error('Error fetching data from API:', error);
      return 'Error fetching data';
    }
  });

  return cbAskAdapter;
}

// Apply special styles to messages starting with "!"
function applySpecialStyles() {
  const messages = document.querySelectorAll('.nlux-comp-message .nlux-markdown-container');
  messages.forEach((message) => {
    const parent = message.parentElement.parentElement;
    if (message.textContent.trim().startsWith('!')) {
      parent.classList.add('message-special');
    } else {
      parent.classList.remove('message-special');
    }
  });
}


export default function CbAsk({ setMessageResponsePairs, messageResponsePairs }) {
  const adapter = useCbAskAdapter({ setMessageResponsePairs, messageResponsePairs });
  const { strings } = useLanguage();
  const inputRef = useRef(null);

  // Set focus on input field when the component is mounted
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  // Function to handle new messages
  const handleNewMessage = (message) => {
    // Logic to handle new messages, e.g., updating state or passing to AiChat component

    // Re-focus on input after the new message is handled
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  // 
  // https://docs.nlkit.com/nlux/examples/conversation-starters
  return (
    <AiChat
      adapter={adapter}

      conversationOptions={{
        conversationStarters:conversationStarters,
      }}
        composerOptions={{
          autoFocus: false,
        }}
        onMessageSend={handleNewMessage}
        personaOptions={{
          assistant: {
            name: strings.frontpage.botName,
            avatar: image_botti,
            tagline: strings.frontpage.firstPrompt,
          },
          user: {
            name: 'Sinä',
            avatar: image_user,
          },
        }}
      >
    </AiChat>

  );
}