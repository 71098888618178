export const handleIframeDrag = (e) => {
    e.preventDefault();
  
    const iframeElement = window.frameElement;
    const startX = e.clientX;
    const startY = e.clientY;
    const initialLeft = parseInt(iframeElement.style.left || 0, 10);
    const initialTop = parseInt(iframeElement.style.top || 0, 10);
  
    const onMouseMove = (moveEvent) => {
      const deltaX = moveEvent.clientX - startX;
      const deltaY = moveEvent.clientY - startY;
  
      iframeElement.style.left = initialLeft + deltaX + 'px';
      iframeElement.style.top = initialTop + deltaY + 'px';
    };
  
    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };
  