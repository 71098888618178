function formatResponseAsHTML(response) {
  const answer = response.answer || 'No answer found';
  const refs = response.refs || [];

  // Use a set to store unique doc_names
  const uniqueDocNames = new Set();
  const maxRefsToDisplay = 3;
  let refsDisplayed = 0;

  // Initialize the HTML output
  //let htmlOutput = `<h4>Answer:</h4><p>${answer}</p>`;
  let htmlOutput = `<p>${answer}</p>`;

  // Only add references section if there are any references to display
  if (refs.length > 0) {
    htmlOutput += `<h4>Linkit:</h4><ul>`;

    // Loop through the references, checking for uniqueness and displaying a maximum of 3
    refs.forEach((ref) => {
      if (refsDisplayed >= maxRefsToDisplay) {
        return;
      }

      if (!uniqueDocNames.has(ref.doc_name)) {
        uniqueDocNames.add(ref.doc_name);
        refsDisplayed += 1;

        // Check if 'pdf' is in the doc_name and adjust the display accordingly
        let link = '';
        if (ref.doc_name.toLowerCase().includes('pdf')) {
          //link = `<a href="${ref.doc_name}" target="_blank" rel="noopener noreferrer">${ref.doc_name} (page ${ref.page_num})</a>`;
          return;
        } else {
          link = `<a href="${ref.doc_name}" target="_blank" rel="noopener noreferrer">${ref.doc_name}</a>`;
        }

        // Append the link to the HTML output as a list item
        htmlOutput += `<li>${link}</li>`;
      }
    });

    htmlOutput += `</ul>`;
  }

  return htmlOutput;
}

// Default export
export default formatResponseAsHTML;
