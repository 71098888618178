import React, { useState } from 'react';
import { useLanguage } from './LanguageContext'; // Import the useLanguage hook
import LoginModal from './LoginModal';
import WorldFlag from 'react-world-flags'; // Import the WorldFlag component for flags
import './Header.css';

const extractEtunimi = (email) => {
  // Split the email string at the '@' character
  const [namePart] = email.split('@');
  
  // Further split the first part (before the '@') by '.' and return the first part
  const [etunimi] = namePart.split('.');
  
  return etunimi;
};


function Header({ user, handleLoginWithProvider, handleEmailLogin, handleEmailSignUp, handleLogout }) {
  const { strings, changeLanguage, language } = useLanguage(); // Access strings, changeLanguage function, and current language
  const [isMaximized, setIsMaximized] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleMaximize = () => {
    setIsMaximized(!isMaximized);
    window.parent.postMessage({ action: 'maximizeIframe' }, '*');
  };

  const handleClose = () => {
    window.parent.postMessage({ action: 'closeIframe' }, '*');
  };

  const openLoginModal = () => {
    setShowModal(true); // Open the login modal when the login button is clicked
  };

  const closeLoginModal = () => {
    setShowModal(false); // Close the modal
  };

  const headerStyle = user && user.uid ? { backgroundColor: 'green' } : { backgroundColor: '#FF8C00' };

  const toggleLanguage = () => {
    // Toggle between 'fi' and 'en'
    const newLanguage = language === 'fi' ? 'en' : 'fi';
    changeLanguage(newLanguage);
  };

  return (
    <header className="iframe-header" style={headerStyle}>
      <div className="left-buttons">
        {/* Conditional rendering based on user authentication 
            {user.photoURL ? (
              <img src={user.photoURL} alt="User Avatar" className="user-avatar" />
            ) : (
              <i className="fas fa-user-circle user-avatar-placeholder"></i>
            )}
            <i className="fas fa-lock lock-icon"></i>        
        */}

        {user && user.uid ? (
          <div className="user-info">
            <span className="user-name">{extractEtunimi((user.displayName && false) || user.email)}</span>
            <i className="fas fa-lock lock-icon"></i>        
            <button onClick={handleLogout} className="iframe-button"  id="loginButton">
              <i className="fas fa-sign-out-alt icon-spacing"></i> {strings.login.logout}
            </button>
          </div>
        ) : (
          <>
            <button onClick={openLoginModal} className="iframe-button" >
              <i className="fas fa-sign-in-alt icon-spacing"></i>
              <span>{strings.login.logout}</span>
            </button>
            <span className="brand-name">{strings.header.brandName}</span>        
          </>
        )}

      </div>
      
      <div className="home-button">          
        <button
            onClick={() => window.location.reload()} // Switch to English
            className={`iframe-button ${language === 'en' ? 'active-language' : ''}`}
            aria-label="Switch to English"
          >
            <i className="fas fa-home style={{ width: '20px', height: '14px' }}"></i>
        </button>
      </div>

      <div className="right-controls">
          <div className="flags-container">
          <button
            onClick={toggleLanguage}
            className={`iframe-button ${language === 'fi' ? 'active-language' : ''}`}
            aria-label={language === 'fi' ? 'Switch to English' : 'Switch to Finnish'}
          >
            {language === 'en' ? (
              <WorldFlag code="GB" style={{ width: '20px', height: '14px' }} />
            ) : (
              <WorldFlag code="FI" style={{ width: '20px', height: '14px' }} />
            )}
          </button>
        </div>
        <div className="control-buttons">
          <button onClick={handleMaximize} className="iframe-button">
            <i className={`fas ${isMaximized ? 'fa-compress' : 'fa-expand'}`}></i>
          </button>
          <button onClick={handleClose} className="iframe-button">
            <i className="fas fa-times"></i>
          </button>
        </div>
      </div>

      {/* Include the LoginModal component */}
      <LoginModal 
        show={showModal} 
        handleClose={closeLoginModal} 
        handleLoginWithProvider={handleLoginWithProvider} 
        handleEmailLogin={handleEmailLogin} 
        handleEmailSignUp={handleEmailSignUp} 
      />
    </header>
  );
}

export default Header;
