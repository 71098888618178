import React, { useState } from 'react';
import { useLanguage } from './LanguageContext'; // Import the useLanguage hook
import LoginModal from './LoginModal';
import WorldFlag from 'react-world-flags'; // Import the WorldFlag component for flags
import './Header.css';

const extractEtunimi = (email) => {
  // Split the email string at the '@' character
  const [namePart] = email.split('@');
  
  // Further split the first part (before the '@') by '.' and return the first part
  const [etunimi] = namePart.split('.');
  
  return etunimi;
};


export default function HeaderAdmin({ user }) {
  const { strings, changeLanguage, language } = useLanguage(); // Access strings, changeLanguage function, and current language

  const headerStyle = user && user.email=='sulismies@gmail.com' ? { backgroundColor: 'darkgray' } : { backgroundColor: '#FF8C00' };

  if (!(user && user.email=='sulismies@gmail.com')) {
    return null;
  }

  return (
    <header className="iframe-header" style={headerStyle}>
      <div className="left-buttons">
        <span>admin panel</span>
      </div>


      <div className="right-controls">
        <span>stuff on the right</span>
      </div>

    </header>
  );
}


