const strings = {
    login: {
      title: "Login",
      signUpTitle: "Sign Up",
      loginWithGoogle: "Login with Google",
      loginWithFacebook: "Login with Facebook",
      loginWithGithub: "Login with GitHub",
      loginWithEmail: "Login with Email/Password",
      alreadyHaveAccount: "Already have an account?",
      dontHaveAccount: "Don’t have an account?",
      signUp: "Sign Up",
      close: "Close",
      logout: "",
      logIn: "",      
    },
    header: {
      welcome: "Welcome, ",
      logout: "",
      brandName: "TinyBot",
    },
    modal: {
      selectLoginMethod: "Select Login Method",
    },
    frontpage:{
      botName: 'TinyBot',
      firstPrompt: "You can ask me you about Ahaa's products or professional communication at workplace in general."
    }
  };
  
  export default strings;