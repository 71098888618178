// LoginModal.js

//
// See 
// https://console.firebase.google.com/u/0/project/users-kaitsu-app/authentication/settings
// for authenticated domains
// 
import React, { useState, useEffect } from 'react';
import { useLanguage } from './LanguageContext'; // Import the useLanguage hook
import './LoginModal.css';

const LoginModal = ({ show, handleClose, handleEmailLogin, handleEmailSignUp, handleLoginWithProvider }) => {
  const { strings } = useLanguage(); // Access strings for localization

  // Define state variables
  const [view, setView] = useState('provider'); // Possible views: 'provider', 'login', 'signup'
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(''); // Error message state
  const [loading, setLoading] = useState(false); // Loading state

  useEffect(() => {
    if (show) {
      // Reset states when the modal is shown
      setView('provider');
      setEmail('');
      setPassword('');
      setError('');
      setLoading(false);
    }
  }, [show]);

  // Handle Email Login
  const handleEmailLoginSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await handleEmailLogin(email, password);
      handleClose(); // Close the modal after successful login
    } catch (err) {
      setError(strings.login.loginFailed); // Display login error message
    } finally {
      setLoading(false);
    }
  };

  // Handle Email Sign-Up
  const handleEmailSignUpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await handleEmailSignUp(email, password);
      handleClose(); // Close the modal after successful sign-up
    } catch (err) {
      setError(strings.login.signUpFailed); // Display sign-up error message
    } finally {
      setLoading(false);
    }
  };

  // Handle Provider Login
  const handleProviderLogin = async (providerName) => {
    setLoading(true);
    try {
      await handleLoginWithProvider(providerName);
      handleClose(); // Close the modal after successful provider login
    } catch (err) {
      setError(strings.login.loginFailed); // Display login error message
    } finally {
      setLoading(false);
    }
  };

  if (!show) {
    return null; // Don't render the modal if 'show' is false
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {/* Close button with Font Awesome cross mark */}
        <button type="button" onClick={handleClose} className="close-button" aria-label={strings.login.close}>
          <i className="fas fa-times"></i>
        </button>
        <h2>{strings.login.title}</h2>

        {error && <p className="error-message">{error}</p>}
        {loading && <p className="loading-message">{strings.login.processing}</p>}

        {/* Provider login view */}
        {view === 'provider' && (
          <div className="button-column">
            <button onClick={() => handleProviderLogin('google')} className="action-button">
              {strings.login.loginWithGoogle}
            </button>
            {/* <button onClick={() => handleProviderLogin('facebook')} className="action-button">
              {strings.login.loginWithFacebook}
            </button>
            <button onClick={() => handleProviderLogin('github')} className="action-button">
              {strings.login.loginWithGithub}
            </button>
            <button
              type="button"
              onClick={() => setView('login')}
              className="action-button"
            >
              {strings.login.loginWithEmail}
            </button>
            <button
              type="button"
              onClick={() => setView('signup')}
              className="action-button"
            >
              {strings.login.signUp}
            </button> */}
          </div>
        )}

        {/* Email login view */}
        {view === 'login' && (
          <form onSubmit={handleEmailLoginSubmit} className="form-column">
            <div className="form-group">
              <label htmlFor="login-email">{strings.login.email}:</label>
              <input
                id="login-email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="login-password">{strings.login.password}:</label>
              <input
                id="login-password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="action-button">{strings.login.logIn}</button>
            <button
              type="button"
              onClick={() => setView('provider')}
              className="action-button"
            >
              {strings.login.back}
            </button>
          </form>
        )}

        {/* Email sign-up view */}
        {view === 'signup' && (
          <form onSubmit={handleEmailSignUpSubmit} className="form-column">
            <div className="form-group">
              <label htmlFor="signup-email">{strings.login.email}:</label>
              <input
                id="signup-email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="signup-password">{strings.login.password}:</label>
              <input
                id="signup-password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="action-button">{strings.login.signUp}</button>
            <button
              type="button"
              onClick={() => setView('provider')}
              className="action-button"
            >
              {strings.login.back}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default LoginModal;
