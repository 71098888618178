const conversationStarters = [
    {
        icon: <i class="csicons fas fa-shopping-cart">&nbsp;</i>,  // added accessibility labels for emoji
        label: 'Ahaan tuotteet',
        prompt: 'Mitä ovat Ahaa Vision Oy:n tuotteet ja palvelut?'
    },
    {
        icon: <i class="csicons fas fa-envelope"></i>,  // added accessibility labels for emoji
        label: <span>Yhteys&shy;tiedot</span>,
        prompt: 'Kirjoita ensin Annen ja sitten Ahaan yhteystiedot.'
    },
    {
        icon: <i class="csicons fas fa-user"></i>,  // added accessibility labels for emoji
        label: 'Kirjaudu sisään',
        prompt: '!login'
    },
    {
        icon: <i class="csicons fas fa-info-circle"></i>,  // added accessibility labels for emoji
        label: <span>Pikku&shy;botti</span>,
        prompt: 'Kerro minulle, mihin PikkuBotin toiminta perustuu.'
    }

];

export default conversationStarters;