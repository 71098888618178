const strings = {
  login: {
    title: "Kirjaudu",
    signUpTitle: "Rekisteröidy",
    loginWithGoogle: "Kirjaudu Googlella",
    loginWithFacebook: "Kirjaudu Facebookilla",
    loginWithGithub: "Kirjaudu GitHubilla",
    loginWithEmail: "Kirjaudu sähköpostilla/salasanalla",
    alreadyHaveAccount: "Onko sinulla jo tili?",
    dontHaveAccount: "Eikö sinulla ole tiliä?",
    signUp: "Rekisteröidy",
    close: "Sulje",
    logout: "",
    logIn: "",    
  },
  header: {
    welcome: "Tervetuloa, ",
    logout: "Poistu",
    brandName: "PikkuBotti",
  },
  modal: {
    selectLoginMethod: "Valitse kirjautumistapa",
  },
  frontpage:{
    botName: 'Pikkubotti',
    firstPrompt: "Kysy minulta Ahaan tuotteista tai ammatillisesta vuorovaikutuksesta."
  }
};

export default strings;
